<template>
  <span v-if="!!rowData.isRecordableCall">
    <button
      v-if="!loading"
      class="btn btn-text shadow-none p-2"
      @click="playSound()"
    >
      <i class="fa fa-volume-up" aria-hidden="true"></i>
    </button>
    <span v-else>
      <half-circle-spinner
        slot="loading"
        :animation-duration="1500"
        :size="25"
        color="#74b9ff"
        class="mx-auto"
      />
    </span>
    <modal inno :isOpen="playerModalOpen" @dismiss="dismiss">
      <SoundPlayer :soundFile="audioUrl" ref="soundPlayer" />
    </modal>
  </span>
</template>

<script>
import HalfCircleSpinner from 'epic-spinners/src/components/lib/HalfCircleSpinner'
import CDRService from '../../../services/cdr.service'
import Modal from '../../../elements/Modal.vue'
import SoundPlayer from '../../../elements/SoundPlayer.vue'

export default {
  name: 'CallActions',
  props: {
    rowData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      audioUrl: '',
      loading: false,
      playerModalOpen: false
    }
  },
  methods: {
    async playSound() {
      this.loading = true

      try {
        const url = await CDRService.getAgentCDRRecord(this.rowData.id)
        this.audioUrl = url
        this.playerModalOpen = true
      } catch (error) {
        this.status = 'paused'
        this.toast("can't play this sound", {
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    dismiss() {
      this.playerModalOpen = false
      this.$refs.soundPlayer.waveSurfer.stop()
    }
  },
  components: {
    HalfCircleSpinner,
    Modal,
    SoundPlayer
  }
}
</script>
<style lang="scss" scoped></style>
